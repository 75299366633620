import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <div id="contact" className="container contact" data-aos="fade-up">
      <h1>Let's get in touch!</h1>
      <p>
        Feel free to contact me through either my{" "}
        <a href="mailto:anthonypang99@gmail.com">
          <strong>Email</strong>
        </a>{" "}
        or my{" "}
        <a
          href="https://www.linkedin.com/in/antpang/"
          target="_blank"
          rel="noreferrer"
        >
          <strong>LinkedIn</strong>
        </a>
      </p>
    </div>
  );
};

export default Contact;
