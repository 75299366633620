import React from "react";
import "./Menu.css";

const menuItems = [
  {
    title: "Home",
    link: "#hero",
  },
  {
    title: "About",
    link: "#about",
  },
  {
    title: "Projects",
    link: "#projects",
  },
  {
    title: "Contact",
    link: "#contact",
  },
];

const Menu = (props) => {
  const onMenuItemClick = () => {
    props.toggleMenu();
  };

  return (
    <div className="menu">
      <ul>
        {menuItems.map((item) => (
          <li>
            <a className="navLink" href={item.link} onClick={onMenuItemClick}>
              {item.title}
            </a>
          </li>
        ))}
        <li>
          <button
            className="themeButton"
            onClick={() => {
              onMenuItemClick();
              props.toggleTheme();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
              />
            </svg>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Menu;
